<template>
    <div>
        <Header />
        <div class="container">
            <div class="row">
                <div class="col-md-8 mb-5">
                    <component :is="hrefs('/journals').content" />
                </div>
                <div class="col-md-4 mb-5">
                    <NewsList />
                    <component :is="hrefs('/contacts').content" />
                </div>
                
            </div>
            <div class="row">
                <div class="col-md-4 mb-5" v-for="journal in journals" :key="journal.meta.issn">
                    
                    <div class="card h-100">
                        <router-link :to="`/journal/${journal.meta.issn}`">
                            <img class="card-img-top mt-3" :src="journal.meta.image" :alt="journal.meta.title">
                        </router-link>
                        <div class="card-body">
                            <h4 class="card-title">{{journal.meta.title}}</h4>
                            <p class="card-text">{{journal.meta.description}}</p>
                        </div>
                        <div class="card-footer">
                            <router-link :to="`/journal/${journal.meta.issn}`" class="btn btn-primary">
                                <font-awesome-icon icon="search" />
                                {{translation.findOutMore}}
                            </router-link>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import NewsList from '../components/NewsList'
//import { mapGetters } from 'vuex'

export default {
    name: 'Journals',
    components: {
        NewsList
    },
    data: () => ({
        
    }),
    computed: {
        journals() {
            return [].concat(this.hrefs(`/journals/*`)).sort( (a,b) => a.meta.order - b.meta.order)
        },
        doc() {
            return this.hrefs('/journals')
        },
        //... mapGetters(['journalsList'])
    }
}
</script>

<style lang="scss" scoped>
img.card-img-top {
    width: 50%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 300px;
}
</style>