<template>
    <div>
        <div class="card-header">
            <button type="button" class="btn btn-info mr-3" @click="$router.back()">
                <font-awesome-icon icon="chevron-circle-left" />
                {{translation.back}}
            </button>
            <h5>{{translation.articlesByAuthor}}</h5>
        </div>
        <ArticleCard :article="article" v-for="article in articles" :key="`list-${article.id}`" />
    </div>
</template>

<script>
import ArticleCard from '../components/ArticleCard'

export default {
    components: {ArticleCard},
    props: ['journal'],
    data: () => ({
        articles: []
    }),
    async mounted() {
        this.articles = await this.$store.dispatch('loadAuthorArticles', this._.pick(this.$route.params, ['issn', 'authorId']) )
    }
}
</script>

<style lang="scss">
.card-header {
    * {
        display: inline;
    }
}
</style>