//import Vue from 'vue'
const fb = require('../firebaseConfig.js')

const MAX_RESULTS = 10

export default {

    state: () => ({
        searchResult: [],
        hasMoreSearchResults: false,
        loadingArticles: false,
        article: null,
        searchSnapshot: null,
        lastQuery: null
    }),

    actions: {
        async flushSearch({commit}) {
            commit('flush')
        },

        async fetchArticles({state, commit, dispatch}, payload) {
            commit('flush')
            commit('loading', true)
            state.lastQuery = fb.articlesCollection
                                    .where('issn', '==', payload.issn)
                                    .orderBy('year', 'desc')
                                    .orderBy('volume', 'desc')
                                    .orderBy('issue', 'desc')
                                    .orderBy('pages', 'asc')
                                    
            await dispatch('fetchMoreSearchResults', MAX_RESULTS)
        },

        async doSearch({state, commit}, payload) {
            commit('flush')
            commit('loading', true)
            let response = await fb.functions.search(payload)
            if(!response || !response.data || !response.data.result) throw('No data')
            for(let doc of response.data.result) {
                commit('addItem', Object.assign(doc._source, {highlight: doc.highlight}))
            }
            state.totalDocuments = response.data.result.langth
            commit('loading', false)
        },

        async fetchJournalIssues({commit, dispatch, state}, {issn, issueId}) {
            commit('flush')
            commit('loading', true)

            let doc = await fb.journalsCollection.doc(issn).collection('issues').doc(issueId).get()
            let issue = await doc.data()
            state.lastQuery = fb.articlesCollection

            state.searchSnapshot = null
            for(let label in issue) {
                state.lastQuery = state.lastQuery.where(label, '==', issue[label])
            }
            state.lastQuery = state.lastQuery.orderBy('pages', 'asc')
            await dispatch('fetchMoreSearchResults', Infinity)
        },

        async fetchMoreSearchResults({ state, commit }, limit) {
            if (state.searchSnapshot) {
                state.searchSnapshot = await state.lastQuery.startAfter(state.searchSnapshot.docs.pop()).limit(limit || state.searchSnapshot.$f.limit).get()
            } else {
                state.searchSnapshot = await state.lastQuery.limit(limit).get()
            }            
            state.hasMoreSearchResults = state.searchSnapshot.docs.length == MAX_RESULTS
            for(let doc of state.searchSnapshot.docs || []) {
                commit('addItem', {id: doc.id, ... await doc.data()})
            }
            commit('loading', false)
        },

        async loadArticle({ commit }, articleId) {
            commit('loading', true)
            let article = await fb.articlesCollection.doc(articleId).get()
            commit('setArticle', await article.data())
            commit('loading', false)
        },

        async loadAuthorArticles({ commit }, options) {
            commit('loading', true)
            let author = await fb.journalsCollection.doc(options.issn).collection('authors').doc(options.authorId).get()
            let articles = await fb.articlesCollection
                                    .where(fb.admin.firestore.FieldPath.documentId(), 'in', await author.data().articles || [])
                                    .get()
            let result = []
            await articles.docs.forEach(async doc => result.push({ id: doc.id, ...await doc.data() }))
            commit('loading', false)
            return result
        }
    },

    mutations: {
        flush(state) {
            state.searchSnapshot = null
            state.lastQuery = null
            state.searchResult = []
            state.hasMoreSearchResults = false
        },
        loading(state, value) {
            state.loadingArticles = value
        },
        addItem(state, payload) {
            state.searchResult.push(payload)
        },
        setArticle(state, article) {
            state.article = article
            //Vue.set(state.article, article)
        }
    },

    getters: {
        searchResult(state) {
            return {items: state.searchResult, more: state.hasMore}
        },
        article(state) {
            return state.article
        },
        loadingArticles(state) {
            return state.loadingArticles
        }
    }
}