<template>
    <div v-if="doc">
        <Header v-if="doc.meta.header == 'large'" />
        <Small-Header v-if="doc.meta.header == 'small'" />
        <div class="container">
            <component :is="doc.content" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'Page',
    mounted() {
        // if(!this.doc) {
        //     this.$router.back()
        // }
    },
    computed: {
        doc() {
            let href
            if(this.$attrs.href) href = this.$attrs.href
            else if (this.$route.params.href) href = '/' + this.$route.params.href
            else if (this.$route.query.href) href = this.$route.query.href
            return this.hrefs( decodeURIComponent(href).replace('//', '/') )
        }
    }
}
</script>